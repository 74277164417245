// DASHBOARD.SCSS 
// METHOD FOR CHANGE - 2017
.dash_container .dash_content  .tab-content{
  background-color: white;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  padding-bottom: 2em;
  @media (max-width: $screen-sm-max) {
    margin-top: 2em;
  }
}

.dash_container .dash_content h3{
  margin: 20px 0px;
}

.not_center{
  text-align: left !important;
}

.panel-heading{
  font-size: 1.5em;
  padding-top:15px 0px;
  background: $dashboardbg !important;
  color: white !important;
  border-radius: 0px !important;
}

.dash_container .dash_menu{
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: $screen-sm-min) {
    width: 100%;
    float: left;
  }
  @media (min-width: $screen-md-min) {
    width: 25%;
    float: left;
  }
  @media (min-width: $screen-lg-min) {
    width: 20%;
    float: left;
  }
}

.dash_container .dash_menu .nav-pills{
  border: none;
}

.menu-title{
  padding: 10px 15px; 
  border-top: 1px solid #eaecef;
  border-bottom: 1px solid #eaecef;
  font-size: 1.2em;
}

.tab-content h3{
    color: $dashboardbg !important;
    font-weight: bold;
}

.tab-content img{
  max-width: 100% !important;
}

.mce-btn button i, .mce-btn button span {
  color:white !important;
}
.mce-caret{
  color:white !important;
  border-top-color: white !important;
}

.mce-menu-item-normal:hover{
  background-color: $item-hover-1-nav-side !important;
}

.dash_navigation li a{
  border-radius: 0px !important;
  color: $txtMain;
}
.dash_navigation li a:hover{
  background-color: $item-hover-2-nav-side;
}
.nav-pills > li.active > a, .dash_navigation li a:focus{
  background-color: $item-hover-1-nav-side !important;
  color:white;
}

.dash_container{
  .dash_content{
    padding: 20px !important;
  }
  .dash_menu{
    padding: 0px !important;
  }
  .dash_menu li a, .dash_navigation li a{
    border-radius: 0px !important;
    color: $txtMain;
  }
  .dash_menu li a:hover, .dash_navigation li a:hover{
    background-color: $item-hover-2-nav-side;
  }
  .dash_menu li a:focus, .nav-pills > li.active > a, .dash_navigation li a:focus{
    background-color: $item-hover-1-nav-side !important;
    color:white;
  }
  .dash_menu + .dash_content{
    position: relative;
    min-height: 1px;

    @media (min-width: $screen-sm-min) {
      width: 100%;
      float: left;
    }
    @media (min-width: $screen-md-min) {
      width: 73%;
      margin-left: 2% !important;
      float: left;
    }
    @media (min-width: $screen-lg-min) {
      width: 78%;
      margin-left: 2% !important;
      float: left;
    }
  }
}
