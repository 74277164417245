/* Template app.blade.php */
html, body {
  background-color: $bgHeaderFooter !important;
  color: $txtMain;
  font-family: $fontMain;
}

a {
  text-decoration: none !important;
  outline:0 !important;
}

#app {
  position: relative !important;
  background-color: $bgGlobal !important;
  min-height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
}
.entete {
  background-color: $bgHeaderFooter !important;
  border-bottom: 1px solid #eaecef !important;
  margin: 0;
  position: fixed;
  width: 100%;
}
.container-fluid {
  padding-left: 10vh;
  padding-right: 10vh;
}
.slogan {
  color : $main1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 0.9em;
}
.navbar-right li, .navbar-left li {
  margin-top: 0.9vh;
  max-width: 50vh;
}
.rubrique:hover {
  border-bottom: 2px solid $main1;
}
.dropdown-menu li{
    margin: 0;
}
.dropdown-menu li a{
    padding: 10px;
}
#mfc_logo {
  max-width: 350px;
  margin-top: 5px;
  padding: 0;
}

/* Search bar */
.searchbox-container{
  margin-top: 10px;
  margin-right: 10px;
  min-height: $searchBoxSize;
}
.searchbox{
  position:relative;
  min-width:$searchBoxSize;
  width:0%;
  height:$searchBoxSize;
  float:right;
  overflow:hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  transition: width 0.3s;
}
.searchbox input {
  border: none !important;
}
.searchbox-input{
  top:0;
  right:0;
  border:0;
  outline:0;
  width:100%;
  height:$searchBoxSize;
  margin:0;
  padding:0px 55px 0px 20px;
  font-size:1em;
}
.searchbox-icon{
  width:$searchBoxSize;
  height:$searchBoxSize;
  display:block;
  position:absolute;
  top:0;
  font-size:22px;
  right:0;
  padding:0;
  margin:0;
  border:0;
  outline:0;
  line-height:$searchBoxSize;
  text-align:center;
  cursor:pointer;
}
.searchbox-open{
  width:100%;
}

/* Carousel */
#myCarousel .nav a small
{
    display: block;
}
.carousel-caption {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    left: 0;
}
#myCarousel .nav
{
    border-top: 1px solid white;
}
#myCarousel .nav li a
{
    border-right: #000;
    color: white !important;
    border-right: 1px solid white;
    background: $bgButtons;
}
.end_nav {
    border-right: 0px !important;
}
#myCarousel .nav li a:hover
{
    background: #E0473E;
}
#myCarousel img
{
    width: 100%;
    max-height: 600px;
}
.nav-justified > li > a
{
    border-radius: 0px;
}

/* Welcome content */
/* Pad the edges of the mobile views a bit */
.methods,
.community {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
}
/* Center align the text within the three columns below the carousel */
.methods .col-lg-4,
.community .col-lg-4 {
    text-align: center;
    margin-bottom: 20px;
}
.methods h2,
.community h2 {
    font-weight: normal;
}
.methods .col-lg-4 p,
.community .col-lg-4 p {
    margin-left: 10px;
    margin-right: 10px;
}

/* Community */
.domains {
    position: relative;
    height: 3em;
    width: 13.5em;
    margin: 150px 450px;
}
.domains li {
    display: block;
    height: 4em;
    line-height: 4em;
    margin: -2.2em;
    position: absolute;
    -webkit-transition: -webkit-transform .7s;
    -moz-transition: -moz-transform .7s;
    -ms-transition: -ms-transform .7s;
    transition: transform .7s;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    text-align: center;
    width: 4em;
}
.domains a {
    color: #fffdf0;
    display: block;
    height: 4em;
    line-height: 6em;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 4em;
}
.domains li:hover {
  -webkit-transform: scale(1.3,1.3) rotate(45deg);
    -moz-transform: scale(1.3,1.3) rotate(45deg);
  -ms-transform: scale(1.3,1.3) rotate(45deg);
  transform: scale(1.3,1.3) rotate(45deg);
}
.domain {
    background: #155b9d;
}

#navbar-projects {
    border-radius: 0;
    border: none;
    border-top: 1px solid #eaecef;
    border-bottom: 1px solid #eaecef;
}

/* Articles */
.article-content {
  padding: 30px;
}
.methodes li {
  margin: 19px;
}

/* Contact Form */
section#contact {
  background-color: $bgButtons;
  color: $txtButtons;
  padding: 30px 0px;
}
section#contact .section-heading {
  color: white;
}
section#contact .form-group {
  margin-bottom: 25px;
}
section#contact .form-group input:focus,
section#contact .form-group textarea:focus {
  box-shadow: 0px 0px 5px rgba(255,255,255,1) !important;
}
section#contact .form-group input,
section#contact .form-group textarea {
  padding: 10px;
}
section#contact .form-group input.form-control {
  height: auto;
}
section#contact .form-group textarea.form-control {
  resize: none;
  height: 185px;
  width: 100%;
}
section#contact .fa{
  color:$txtButtons;
}
.about_our_company{
  text-align: center;
}
.about_our_company h1{
  font-size: 3em;
  margin-bottom: 30px;
}

footer {
  padding-top: 5px;
  border-bottom: none !important;
  border-radius: none !important;
  width: 100%;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.footer-visible {
  border-top: 1px solid #eaecef !important;
  background-color: $bgGlobal;
}

#innovacs_logo {
  max-height: 40px;
  margin-top: 10px;
}

#innovacs_uga {
  margin-top: 2vh;
}

footer .fa, .fa-search {
  color: grey;
}
footer .fa:hover, .fa-search:hover {
  color: $main1;
}

#uga_logo {
  max-height: 65px;
}

.fa{
  padding:0px !important;
}

/* Template formulaires */

.panel {
  margin-top: 50px;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.panel-default {
  border-color: #ddd;
}
.form-control, input {
  border-radius: 2px !important;
}
.form-control:focus, input:focus {
  box-shadow: 0px 0px 5px $inputShadow !important;
  border: 1px solid $inputShadow !important;
}
button, .btn {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
  border: none !important;
  border-radius: 1px !important;
  background-color: $bgButtons !important;
  color: $txtButtons !important;
}
